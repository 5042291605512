<script>
import Controlador from '@/components/leiloes/components/controlador/ControladorV2'
import LeiloeiroStats from "../tela-leiloeiro/include/LeiloeiroStats"
import Estatisticas from "./acompanhamento/Stats"
import ComitenteLote from "@/components/leiloes/components/monitor-comitente/acompanhamento/Lote"
import LeilaoTempoStats from "@/components/leiloes/components/tela-leiloeiro/include/LeilaoTempoStats"
import {pendenciasAprovacao} from "@/domain/comitentes/services"
import Aprovar from "@/components/leiloes/components/monitor-comitente/acompanhamento/Aprovar";
import {STATUS_CONDICIONAL} from "@/domain/leiloes/helpers/LoteStatus"
import Contraproposta from "@/components/leiloes/components/monitor-comitente/acompanhamento/Contraproposta";

export default {
  name: 'AcompanhamentoComitente',
  mixins: [Controlador],
  components: {
    Contraproposta,
    Aprovar,
    LeilaoTempoStats,
    ComitenteLote,
    LeiloeiroStats,
    Estatisticas
  },
  data() {
    return {
      visibledKeyboardStats: true,
      pendencias: null
    }
  },
  computed: {},
  mounted() {
    this.page.setActive('aco')
    this.load()
    this.findPendencias()
    let chat = document.createElement('script')
    const loc = location.hostname
    let url = 'https://code.tidio.co/eijaeffsibycl81t5rxmpb1cgvlnlfwk.js'
    if (loc.indexOf('buaizleiloes.com.br') > -1) {
      url = 'https://code.tidio.co/orqhlmegbvtlphkakkgud43wnhpmxhgv.js'
    }
    chat.setAttribute('src', url)
    document.head.appendChild(chat)
  },
  destroyed() {
  },
  methods: {
    /**
     * @TODO: RECEBER VIA WEBSOCKET
     */
    __mudaLote(data) {
      console.log('Muda lote', data)
      if (!this.isLeilaoComunication(data)) return
      this.findPendencias()
      if (typeof this.seguirLeilao !== 'undefined' && this.seguirLeilao === false) return
      this.leilao = Object.assign({}, this.leilao, {pregaoAtivo: data.pregao})
      if (this.lote) {
        this.loteAnterior = this.lote
      }
      this.lote = data.pregao.lote
      if (this.lote.status === 2) {
        this.ativaTimer()
      }
    },
    __statusLote(data) {
      if (!this.isLoteComunication(data.lote.id)) return
      this.lote = Object.assign({}, this.lote, data.lote)
      if (data.lote.status !== 2) {
        this.desativaTimer()
      } else {
        this.$nextTick(() => {
          this.ativaTimer()
        })
      }
      if (data.lote.status === STATUS_CONDICIONAL) {
        this.findPendencias()
      }
    },
    findPendencias() {
      pendenciasAprovacao(this.leilao.id)
          .then(({data}) => {
            this.pendencias = data
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    contraproposta (lote) {
      console.log('Contraproposta ', lote)
      this.$refs.contraproposta.digitarLance(lote)
    }
  },
  meta: {
    title: 'Acompanhamento do Comitente',
    name: 'Acompanhamento do Comitente'
  }
}
</script>

<template>
  <div v-if="!loading" class="leiloeiro acomitente" :class="['show-keyboard']">
    <!--    <stats-sup :leilao="leilao" />-->

    <div class="container-app">
      <screen-options :active-keyboard="visibledKeyboard" :active-resume="visibledKeyboardStats"
                      @toggleKeyboard="showKeyboard" @toggleKeyboardStats="showKeyboardStats"/>

      <leiloeiro-stats :leilao="leilao" :lote="lote"/>

      <comitente-lote :show-descricao="false" :leilao="leilao" :lote="lote"/>

      <div class="column-3">
        <div class="cronometro-container flex flex-center">
          <status :leilao="leilao" :lote="lote" mostrarAvisoLanceAutomatico/>
        </div>
        <div class="leilao-stats">
          <leilao-tempo-stats :leilao="leilao" :lote="lote"/>
        </div>
      </div>

      <estatisticas
          ref="keyboardStats"
          :leilao="leilao"
          :lote="lote"
          :state="visibledKeyboardStats"
          @estatisticas="updateEstatisticas"
      >

        <aprovar class="m-t" v-for="lote in pendencias" :key="lote.id" :lote="lote" @atualizado="findPendencias" @contraproposta="contraproposta" />
        <contraproposta ref="contraproposta" />
      </estatisticas>
    </div>
  </div>
  <div class="controlador-v2 flex flex-center items-center column h-full no-select text-white"
       style="justify-content: center !important" v-else>
    <div class="m-b-xs">
      <u-spinner-uloc/>
    </div>
    Carregando a aplicação
  </div>
</template>

<style src="../../assets/acompanhamento-comitente.styl" lang="stylus"/>
